import React from "react"
import { graphql } from "gatsby"

import {
  Attorneys,
  Hero,
  Layout,
  PraticeAreas,
  Seo,
  CallToAction,
  Welcome,
} from "../components"

export const query = graphql`
  {
    allDatoCmsWelcome {
      edges {
        node {
          seo {
            description
            title
          }
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const {
    allDatoCmsWelcome: { edges: welcome },
  } = data

  return (
    <Layout>
      {welcome.map(({ node }) => {
        const { title, description } = node.seo

        return <Seo title={title} description={description} />
      })}
      <Hero />
      <Welcome />
      <Attorneys />
      <PraticeAreas />
      <CallToAction />
    </Layout>
  )
}

export default HomePage
